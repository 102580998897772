<template>
    <div class="page-wrapper">
        <v-card color="basil" min-height="900">
            <v-card-title class="text-center justify-center py-6">
                <h1 class="font-weight-bold display-3 basil--text">IPAD FAIR</h1>
            </v-card-title>

            <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="basil"
                    grow
            >
                <v-tab
                        @click="load_tab(item)"
                        v-for="item in items"
                        :key="item.id"
                >
                    {{ item.name }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item
                        v-for="item in items"
                        :key="item.id"
                >
                    <v-card flat color="basil">
                        <v-card-text>
                            <div v-if="item.id === 1">
                                <div v-if="!confirm_factory && !add_products">
                                    <h2 class="headline mb-5">
                                        New Factory
                                    </h2>

                                    <v-file-input
                                            label="File input"
                                            filled
                                            required
                                            :error-messages="checkProductImage"
                                            v-model="factory.image"
                                            @input="$v.factory.image.image.$touch()"
                                            @blur="$v.factory.image.$touch()"
                                            capture="camera"
                                            accept="image/*"
                                            prepend-icon="mdi-camera"
                                    ></v-file-input>

                                    <v-btn
                                            color="blue-grey darken-4"
                                            @click="save_factory(factory)"
                                            block
                                            outlined
                                            class="mt-10 mb-10"
                                    >
                                        Next
                                    </v-btn>
                                </div>
                                <div v-if="confirm_factory">
                                    <h2 class="display-2">
                                        Confirming details
                                    </h2>

                                    <v-text-field
                                          v-model="factory.name"
                                          :counter="200"
                                          label="Factory Name"
                                        ></v-text-field>

                                    <v-text-field
                                          v-model="factory.address"
                                          :counter="400"
                                          label="Address"
                                        ></v-text-field>

                                    <v-text-field
                                            v-model="factory.email"
                                            :counter="200"
                                            label="Email"
                                    ></v-text-field>

                                    <v-text-field
                                          v-model="factory.phone"
                                          :counter="200"
                                          label="Phone"
                                        ></v-text-field>

                                    <v-text-field
                                            v-model="factory.fax"
                                            :counter="15"
                                            label="fax"
                                    ></v-text-field>

                                    <v-text-field
                                            v-model="factory.qq"
                                            :counter="15"
                                            label="QQ"
                                    ></v-text-field>

                                    <v-btn
                                          color="blue-grey darken-4"
                                          @click="confirm_factory_details(factory)"
                                        >
                                          <v-icon>
                                            mdi-mdi-content-save-move
                                          </v-icon> Confirm Factory Information
                                        </v-btn>
                                </div>
                                <div v-if="add_products && !confirm_factory">
                                    <h2 class="headline mb-5">
                                        Adding Products ({{ factory.products.length }})
                                    </h2>

                                    <p class="small mb-1">
                                        {{ factory.name }}
                                    </p>

                                    <p class="small mb-10">
                                        Before these products can go live to the system addition info will need
                                        to be added.
                                    </p>

                                    <v-file-input
                                            label="File input"
                                            filled
                                            required
                                            :error-messages="checkProductImage"
                                            @input="$v.product.image.$touch()"
                                            @blur="$v.product.image.$touch()"
                                            v-model="product.image"
                                            capture="camera"
                                            accept="image/*"
                                            prepend-icon="mdi-camera"
                                    ></v-file-input>

                                    <v-text-field
                                            v-model="product.name"
                                            required
                                            :error-messages="checkProductName"
                                            @input="$v.product.name.$touch()"
                                            @blur="$v.product.name.$touch()"
                                            :counter="200"
                                            label="Name"
                                    ></v-text-field>

                                    <v-text-field
                                            label="Item Number"
                                            required
                                            :error-messages="checkProductItemNumber"
                                            @input="$v.product.item_number.$touch()"
                                            @blur="$v.product.item_number.$touch()"
                                            v-model="product.item_number"
                                            class=""></v-text-field>

                                    <v-text-field
                                            v-model="product.fob_price"
                                            required
                                            :error-messages="checkProductFob"
                                            @input="$v.product.fob_price.$touch()"
                                            @blur="$v.product.fob_price.$touch()"
                                            label="Fob Price"
                                    ></v-text-field>

                                    <v-text-field
                                            v-model="product.moq"
                                            required
                                            :error-messages="checkProductMoq"
                                            @input="$v.product.moq.$touch()"
                                            @blur="$v.product.moq.$touch()"
                                            label="Product MOQ"
                                    ></v-text-field>

                                    <v-btn
                                            color="blue-grey darken-4"
                                            outlined
                                            block
                                            @click="add_product(product)"
                                    >
                                        Add Product / Add Another
                                    </v-btn>
                                </div>
                            </div>
                            <div v-if="item.id === 2">
                                <h2 class="headline">
                                    Factories
                                </h2>

                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Address
                                            </th>
                                            <th class="text-center" style="min-width:200px;">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(factory, index) in factories">
                                            <td>
                                                {{ factory.name }}
                                            </td>
                                            <td>
                                                {{ factory.address }}
                                            </td>
                                            <td class="text-center">
                                                <v-btn
                                                      color="blue-grey darken-4"
                                                      fab
                                                      text
                                                      @click="set_edit_factory(factory)"
                                                    >
                                                      <v-icon>
                                                        mdi-pencil
                                                      </v-icon>
                                                    </v-btn>

                                                <v-btn
                                                      color="blue-grey darken-4"
                                                      fab
                                                      text
                                                      @click="delete_factory(factory, index)"
                                                    >
                                                      <v-icon>
                                                        mdi-delete
                                                      </v-icon>
                                                    </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
        <v-dialog v-model="factory_dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="factory_dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Settings</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="factory_dialog = false">
                            Save
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <h2 class="headline">
                    Factory
                </h2>

                <v-divider></v-divider>

                <h2 class="headline">
                    Products
                </h2>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                          color="blue-grey darken-4"
                          text
                          @click=""
                        >
                          Save
                        </v-btn>

                    <v-btn
                          color="blue-grey darken-4"
                          @click=""
                        >
                          Publish
                        </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email, number} from 'vuelidate/lib/validators'

    export default {
        components: {},
        mixins: [validationMixin],

        validations: {
            factory: {
                image: {
                    required
                },
            },
            product: {
                name: {
                    required,
                    minLength:minLength(2),
                    maxLength:maxLength(200)
                },
                item_number: {
                    required,
                },
                image: {
                    required
                },
                fob_price: {
                    required
                },
                moq: {
                    required
                }
            }
        },
        data() {
            return {
                tab: 0,
                add_products: false,
                default_product: {
                    id:0,
                    name:'',
                    item_number: '',
                    image:null,
                    fob_price:0,
                    moq:0,
                    user:0
                },
                product: {
                    id:0,
                    name:'',
                    item_number: '',
                    image:null,
                    fob_price:0,
                    moq:0,
                    user:0
                },
                factories: {},
                user: {},
                items: [
                    {
                        id: 1,
                        name: 'New Factory / Products'
                    },
                    {
                        id: 2,
                        name: 'Fair Factories / Products'
                    }
                ],
                default_factory: {
                    id:0,
                    name: '',
                    address: '',
                    zip_code: '',
                    contact_name: '',
                    phone: '',
                    email: '',
                    products: []
                },
                factory: {
                    id:0,
                    name: '',
                    address: '',
                    zip_code: '',
                    contact_name: '',
                    phone: '',
                    email: '',
                    products: []
                },
                edit_factory: {
                    id:0,
                    name: '',
                    address: '',
                    zip_code: '',
                    contact_name: '',
                    phone: '',
                    email: '',
                    products: []
                },
                factory_dialog: false,
                confirm_factory: false
            }
        },
        created() {
            this.user = JSON.parse(
                localStorage.getItem('user')
            );
            this.default_product.user = this.user.id;
        },
        computed: {
            checkProductMoq() {
                const errors = [];
                if (!this.$v.product.moq.$dirty) return errors;
                !this.$v.product.moq.required && errors.push(   'Moq is required.');
                return errors;
            },
            checkProductFob() {
                const errors = [];
                if (!this.$v.product.fob_price.$dirty) return errors;
                !this.$v.product.fob_price.required && errors.push( 'Fob_price is required.');
                return errors;
            },
            checkProductImage() {
                const errors = [];
                if (!this.$v.product.image.$dirty) return errors;
                !this.$v.product.image.required && errors.push( 'Image is required.');
                return errors;
            },
            checkProductName() {
                const errors = [];
                if (!this.$v.product.name.$dirty) return errors;
                !this.$v.product.name.maxLength && errors.push( 'Name must be at most x characters long');
                !this.$v.product.name.minLength && errors.push( 'Name must be at least x characters long');
                !this.$v.product.name.required && errors.push(  'Name is required.');
                return errors;
            },
            checkProductItemNumber() {
                const errors = [];
                if (!this.$v.product.item_number.$dirty) return errors;
                !this.$v.product.item_number.required && errors.push('Item_number is required.');
                return errors;
            },
            checkFactoryName() {
                const errors = [];
                if (!this.$v.factory.name.$dirty) return errors;
                !this.$v.factory.name.maxLength && errors.push( 'Name must be at most x characters long');
                !this.$v.factory.name.minLength && errors.push( 'Name must be at least x characters long');
                !this.$v.factory.name.required && errors.push(  'Name is required.');
                return errors;
            },
            checkFactoryAddress() {
                const errors = [];
                if (!this.$v.factory.address.$dirty) return errors;
                !this.$v.factory.address.maxLength && errors.push(  'Address must be at most x characters long');
                !this.$v.factory.address.minLength && errors.push(  'Address must be at least x characters long');
                !this.$v.factory.address.required && errors.push(   'Address is required.');
                return errors;
            },
            checkFactoryContactName() {
                const errors = [];
                if (!this.$v.factory.contact_name.$dirty) return errors;
                !this.$v.factory.contact_name.maxLength && errors.push( 'Company_name must be at most x characters long');
                !this.$v.factory.contact_name.minLength && errors.push( 'Company_name must be at least x characters long');
                !this.$v.factory.contact_name.required && errors.push(  'Company_name is required.');
                return errors;
            },
            checkFactoryEmail() {
                const errors = [];
                if (!this.$v.factory.email.$dirty) return errors;
                !this.$v.factory.email.maxLength && errors.push('Email must be at most x characters long');
                !this.$v.factory.email.minLength && errors.push('Email must be at least x characters long');
                !this.$v.factory.email.required && errors.push( 'Email is required.');
                return errors;
            },
            checkZipCode() {
                const errors = [];
                if (!this.$v.factory.zip_code.$dirty) return errors;
                !this.$v.factory.zip_code.maxLength && errors.push( 'Zip_code must be at most x characters long');
                !this.$v.factory.zip_code.minLength && errors.push( 'Zip_code must be at least x characters long');
                !this.$v.factory.zip_code.required && errors.push(  'Zip_code is required.');
                return errors;
            },
            checkFactoryPhone() {
                const errors = [];
                if (!this.$v.factory.phone.$dirty) return errors;
                !this.$v.factory.phone.maxLength && errors.push('Phone must be at most x characters long');
                !this.$v.factory.phone.minLength && errors.push('Phone must be at least x characters long');
                !this.$v.factory.phone.required && errors.push( 'Phone is required.');
                return errors;
            },
        },
        methods: {
            set_edit_factory(factory)
            {
                this.edit_factory = factory;
                this.factory_dialog = true;
            },
            delete_factory(factory, index)
            {
                if(!confirm('Are you sur you would like to delete '+factory.name+' ?'))
                {
                    return false;
                }
                this.$hdelete(
                    this.$http.defaults.baseURL + '/sourcers/fair-factory/'+factory.id+'/').then((response) => {
                        this.factories.splice(index, 1);
                });
            },
            confirm_factory_details(factory)
            {
                console.log("Confirming Factory");
                delete factory.image;

                this.$hpatch(
                    this.$http.defaults.baseURL + '/sourcers/fair-factory/' + factory.id+'/', factory).then((response) => {
                        if(typeof response.data !== 'undefined')
                    {
                        factory.products = [];
                        this.confirm_factory = false;
                        this.add_products = true;
                    }
                });
            },
            load_tab(tab) {
                if (tab.id === 1) {
                    this.load_new_factory_tab()
                } else {
                    this.load_factories_tab();
                }
            },
            load_new_factory_tab() {
                this.add_products = false;
                this.factory = this.default_factory;
            },
            save_factory(factory) {
                this.$v.factory.$touch();
                if (this.$v.factory.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'Please Fill Out The Form Correctly',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                const formData = new FormData();
                formData.append('image', factory.image);

                if (factory.id === 0) {
                    console.log(factory);
                    let send_data = {
                        image: factory.image
                    };
                    console.log(send_data);
                    this.$hpost(this.$http.defaults.baseURL + '/sourcers/fair-factory/', formData).then((response) => {
                        if (typeof response.data !== 'undefined') {
                            console.log(response);
                            this.factory = response.data.data;
                            this.confirm_factory = true;
                        }
                    });
                } else {
                    this.$hpatch(this.$http.defaults.baseURL + '/sourcers/fair-factory/' + factory.id + '/', factory).then((response) => {
                        if (typeof response.data !== 'undefined') {
                            console.log(response);
                        }
                    });
                    this.add_products = true;
                }

            },
            load_factories_tab() {
                // load fair factories
                this.$hget(
                    this.$http.defaults.baseURL + '/sourcers/fair-factory/').then((response) => {
                    if (typeof response.data !== 'undefined') {
                        this.factories = response.data;
                    }
                });
            },
            add_product(product) {

                this.$v.product.$touch();
                if (this.$v.product.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'Please Fill Out The Form Correctly',
                        group: 'notifications',
                        position: 'top right'
                    });

                    return false;
                }

                // save product
                product.factory_id = this.factory.id;
                product.fair_factory = this.factory.id;
                product.user = this.user.id;

                if (product.id === 0) {
                    const formData = new FormData();
                    formData.append('image', product.image);
                    formData.append('moq', product.moq);
                    formData.append('fob_price', product.fob_price);
                    formData.append('name', product.name);
                    formData.append('user', this.user.id);
                    formData.append('fair_factory', this.factory.id);

                    // add to factory
                    this.$hpost(this.$http.defaults.baseURL + '/sourcers/fair-product/', formData).then((response) => {
                        if (typeof response.data !== 'undefined') {
                            this.factory.products.push(
                                response.data
                            ); //add response.data
                            console.log(this.factory.products.length);
                            console.log(this.factory);
                            this.product = {id:0};
                            this.$forceUpdate();
                        }
                    });
                } else {
                    // add to factory
                    this.$hpatch(this.$http.defaults.baseURL + '/sourcers/fair-product/' + product.id + '/', product).then((response) => {
                        if (typeof response.data !== 'undefined') {
                            this.factory.products.push(
                                response.data
                            ); //add response.data
                        }
                    });
                }

                // set product to blank

                // force update
                this.$forceUpdate();
            }
        }
    }
</script>
